import loadCSS from './load-css';
import loadJS from './load-js';
import getMeta from './get-meta';

// expose helper modules
window.enhance = {loadCSS, loadJS, getMeta};
window.enhance.Cookies = window.Cookies;

//const COOKIE_EXPIRE_DAYS_FONTS = 7;
const COOKIE_EXPIRE_DAYS_CSS = 7;
const NAME_FULL_CSS = 'fullcss';
const NAME_FULL_JS = 'fulljs';

/*
 * Load non-critical CSS async on first visit:
 * On first visit to the site, the critical CSS for each template should be inlined in the head,
 * while the full CSS for the site should be requested async and cached for later use.
 * A meta tag with a name matching the NAME_FULL_CSS should have a content attribute referencing the
 * path to the full CSS file for the site.
 * If no cookie is set to specify that the full CSS has already been fetched,
 * load it asynchronously and set the cookie.
 * Once the cookie is set, the full CSS is assumed to be in cache, and the server-side templates
 * should reference the full CSS directly from the head of the page with a link element,
 * in place of inline critical styles.
 */
const fullCSS = getMeta(NAME_FULL_CSS);
if (fullCSS && !window.Cookies.get(NAME_FULL_CSS)) {
	loadCSS(fullCSS.content);
	// set cookie to mark this file fetched
	window.Cookies.set(NAME_FULL_CSS, 'true', {expires: COOKIE_EXPIRE_DAYS_CSS});
}

/*
 * Enhancements for qualified browsers - 'Cutting the Mustard'
 * Only enhance browsers that support document.querySelector (IE8+, etc).
 */
const isQualifiedBrowser = ('querySelector' in document);
if (isQualifiedBrowser) {
	/*
	 * Add scoping classes to HTML element: useful for upgrading the presentation of
	 * elements that will be enhanced with JS behavior
	 */
	document.documentElement.className += ' enhanced';

	/*
	 * Load JavaScript enhancements in one request.
	 * Your DOM framework and dependent component scripts should be concatenated and minified into
	 * one file that we'll load dynamically (keep that file as small as possible!)
	 * A meta tag with a name matching the NAME_FULL_JS should have a content attribute referencing the
	 * path to this JavaScript file.
	 */
	const fullJS = getMeta(NAME_FULL_JS);
	if (fullJS) {
		loadJS(fullJS.content);
	}
}
