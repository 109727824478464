export default function loadJS(src, cb) {
	var head = document.getElementsByTagName('head')[0];
	var done = false;
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.charset = 'utf-8';
	script.async = 'true';
	script.src = src;
	// Attach handlers for all browsers
	script.onload = script.onreadystatechange = function () {
		if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
			done = true;
			if (typeof cb === 'function') {
				cb();
			}
		}
	};
	head.appendChild(script);
}
