/*!
 loadCSS: load a CSS file asynchronously.
 [c]2014 @scottjehl, Filament Group, Inc.
 Licensed MIT
 */
export default function loadCSS(href, before, media) {
	// Arguments explained:
	// `href` is the URL for your CSS file.
	// `before` optionally defines the element we'll use as a reference for injecting our <link>
	// By default, `before` uses the first <script> element in the page.
	// However, since the order in which stylesheets are referenced matters, you might need a more specific location in your document.
	// If so, pass a different reference element to the `before` argument and it'll insert before that instead
	// note: `insertBefore` is used instead of `appendChild`, for safety re: http://www.paulirish.com/2011/surefire-dom-element-insertion/
	const ss = window.document.createElement('link');
	const ref = before || window.document.getElementsByTagName('script')[0];
	const sheets = window.document.styleSheets;
	ss.rel = 'stylesheet';
	ss.href = href;
	// temporarily, set media to something non-matching to ensure it'll fetch without blocking render
	ss.media = 'only x';

	// inject link
	ref.parentNode.insertBefore(ss, ref);
	// This function sets the link's media back to `all` so that the stylesheet applies once it loads
	// It is designed to poll until document.styleSheets includes the new sheet.
	ss.onloadcssdefined = function (cb) {
		let defined;
		for (let i = 0; i < sheets.length; i++) {
			if (sheets[i].href && sheets[i].href.indexOf(href) > -1) {
				defined = true;
			}
		}
		if (defined) {
			cb();
		} else {
			setTimeout(function () {
				ss.onloadcssdefined(cb);
			});
		}
	};
	ss.onloadcssdefined(function () {
		ss.media = media || 'all';
	});
	return ss;
}
