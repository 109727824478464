// getMeta function: get a meta tag by name
// NOTE: meta tag must be in the HTML source before this script is included in order to guarantee it'll be found
export default function getMeta(name) {
	const metaElements = window.document.getElementsByTagName('meta');

	let metaElement;
	for (let i = 0; i < metaElements.length; i++) {
		if (metaElements[i].name && metaElements[i].name === name) {
			metaElement = metaElements[i];
			break;
		}
	}

	return metaElement;
}
